@primary: var(--mainTheme);
// @primary-hover: fade(color(@primary), 10%);
@actionColor: #ff5501;
@main-color: #1f1f1f;
@content-color: #293d66;
@text-color: #4d5e80;
@main-text-color: #1f2e59;
@label-color: #4d5e8c;

.rtl-less(@ltr-property, @rtl-property, @value) {
  [dir='ltr'] & {
    @{ltr-property}: @value;
  }

  [dir='rtl'] & {
    @{rtl-property}: @value;
  }
}
.load-wrap {
  width: 100%;
  height: var(--vh);
  display: flex;
  align-items: center;
  justify-content: center;
}
.load-wrap-middle {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-wrap-small {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
