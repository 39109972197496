@primary: var(--mainTheme);
// @primary-hover: fade(color(@primary), 10%);
@actionColor: #ff5501;
@main-color: #1f1f1f;
@content-color: #293d66;
@text-color: #4d5e80;
@main-text-color: #1f2e59;
@label-color: #4d5e8c;

.rtl-less(@ltr-property, @rtl-property, @value) {
  [dir='ltr'] & {
    @{ltr-property}: @value;
  }

  [dir='rtl'] & {
    @{rtl-property}: @value;
  }
}
.ant-table-wrapper {
  .ant-pagination {
    padding-right: 12px !important;
  }
}

.ant-layout {
  background-color: #f3f7fa !important;
}
.ant-table-column-sort {
  background: #fafafa !important;
}
.ant-table-cell {
  word-break: break-all;
}
.ant-pro-table-column-setting-overlay {
  .ant-popover-inner-content {
    width: 250px !important;
  }
}

.ant-pro-table-column-setting-list-item-title {
  max-width: none !important;
  // min-width: 80px;
}
.common-table {
  .ant-table-cell {
    font-size: 12px;
  }
}

.common-table {
  .ant-table-pagination.ant-pagination {
    margin-block-end: 0;
  }
}

.ant-form-item-explain-error {
  height: 16px !important;
  line-height: 16px !important;
}
.ant-tabs {
  .ant-tabs-tab {
    color: #4d5e8c;
  }
}

.ant-drawer *,
.ant-modal *,
.ant-popover *,
.ant-message *,
.ant-tooltip *,
.ant-select-dropdown *,
.ant-row *,
.ant-layout * {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
}
